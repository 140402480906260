import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';

import { RouterService } from '../services';

@Injectable({ providedIn: 'root' })
export class NotFoundInterceptor implements HttpInterceptor {
  readonly #routerService = inject(RouterService);

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          // Redirect to 404 page when backend responds with a 404.
          if (err.status === 404) {
            this.#routerService.navigateToPMNotFound();
          }
        }
        return throwError(err);
      })
    );
  }
}
