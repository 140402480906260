import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { HomeRoutingModule } from './home/home-routing.module';
import { AuthGuard } from './shared/guards';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home-routing.module').then((m) => m.HomeRoutingModule),
    canActivate: [AuthGuard]
  },
  { path: '**', redirectTo: '/not-found' }
];

const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  // Scroll to top when navigating to a new route.
  scrollPositionRestoration: 'top'
};

@NgModule({
  imports: [
    HomeRoutingModule,
    RouterModule.forRoot(routes, routingConfiguration)
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
