/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';

import { UrlsService } from '@shared/services';

import { RouterActions } from './actions';

@Injectable()
export class RouterEffects {
  readonly #actions$ = inject(Actions);
  readonly #router = inject(Router);
  readonly #urls = inject(UrlsService);

  #createRouterEffect(actionType: any, urlFunc: (action: any) => string) {
    return createEffect(
      () =>
        this.#actions$.pipe(
          ofType(actionType),
          switchMap((action) => this.#router.navigateByUrl(urlFunc(action)))
        ),
      { dispatch: false }
    );
  }

  navigateToProjectManager$ = this.#createRouterEffect(
    RouterActions.navigateToProjectManager,
    () => this.#urls.PM_URL
  );

  navigateToSpanTaskItem$ = this.#createRouterEffect(
    RouterActions.navigateToSpanTaskItem,
    (action) => this.#urls.getTaskTextUrl(action.taskId, action.textId)
  );

  navigateToTaskItem$ = this.#createRouterEffect(
    RouterActions.navigateToTaskItem,
    (action) => this.#urls.getTaskUrl(action.taskId)
  );
}
