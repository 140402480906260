/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';

import { AuthService, RouterService } from '@shared/services';

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {
  readonly #auth = inject(AuthService);
  readonly #routerService = inject(RouterService);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.#auth.token) {
      request = request.clone({
        setHeaders: { Authorization: `Token ${this.#auth.token}` }
      });
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            // Redirect to login on unauthorized access.
            this.#auth.clearStorage();
            this.#routerService.navigateToLogin();
          }
        }
        return throwError(err);
      })
    );
  }
}
