import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { provideMarkdown } from 'ngx-markdown';

import { AccountRoutingModule } from '@account/account-routing.module';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { environment } from '@app/environments';
import { NotFoundInterceptor, TokenInterceptor } from '@shared/interceptors';
import { RouterEffects } from '@store/router/effects';

if (environment.production) enableProdMode();

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      AppRoutingModule,
      BrowserModule,
      // Router store is available for all modules.
      StoreModule.forRoot({ router: routerReducer }),
      EffectsModule.forRoot(RouterEffects),
      StoreRouterConnectingModule.forRoot(),
      // Data Stand modules.
      AccountRoutingModule,
      // Dev/prod conditioned imports.
      environment.imports
    ),
    provideMarkdown(),
    // Set default duration for the snack-bar.
    // https://material.angular.io/components/snack-bar/overview
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 3000,
        horizontalPosition: 'left'
      }
    },
    // Use the outline appearance for all form fields.
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', subscriptSizing: 'dynamic' }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotFoundInterceptor,
      multi: true
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    // Set format for Material Datepicker.
    // https://material.angular.io/components/datepicker/overview#choosing-a-date-implementation-and-date-format-settings
    provideMomentDateAdapter({
      parse: {
        dateInput: 'YYYY-MM-DD'
      },
      display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
      }
    })
  ]
}).catch((err) => console.error(err));
